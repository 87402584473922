@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Raleway:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Raleway', system-ui, sans-serif;
    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
  }
  ::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }

  .pre-wrap {
    white-space: pre-wrap;
  }
}

@layer components {
  .link-with-icon {
    @apply flex gap-3 items-center;
  }

  .link {
    @apply text-link font-semibold underline;
  }

  .summary-link {
    @apply text-summary-link underline;
  }

  .popup-link {
    @apply font-semibold underline text-popup-link;
  }
}

.form-subtitle-compact {
  @apply text-lg mb-6 lg:mb-8;
}

/* Chrome, Safari, Edge, Opera hide input type number steps  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox hide input type number steps */
input[type='number'] {
  -moz-appearance: textfield;
}

.payment-date iframe {
  max-width: 30px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px rgba(var(--content-background)) inset;
}
